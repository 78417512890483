<template>
  <div>
    <table-render :schema="schema" :rows="rows" id="print-returns-list" :key="keyTableRender" :showCheckboxes="true" :selectedRows.sync="selectedRows">
      <template #current_status="scope">
        <span :class="`table_dot--state ${$options.filters.getStatusClasses()[scope.rowdata.status_data.id]}`"></span> {{scope.rowdata.status.name}}
      </template>
      <template #num_return="scope">
        <span><a :href="goTo(scope.rowdata.id, URLS.returns)" target="_blank" class="text-primary">{{scope.rowdata.original_id || scope.rowdata.id}}</a></span>
      </template>
      <template #num_imported="scope">
        <span v-if="scope.rowdata.parent_delivery_id && scope.rowdata.order.imported_id"><a :href="goTo(scope.rowdata.parent_delivery_id, URLS.deliveries)" target="_blank" class="text-primary">{{scope.rowdata.order.imported_id}}</a></span>
        <span v-else-if="!scope.rowdata.parent_delivery_id && scope.rowdata.order.imported_id">{{scope.rowdata.order.imported_id}}</span>
        <span v-else>-</span>
      </template>
      <template #tracking_number="scope">
        {{scope.rowdata.carrier.tracking_number || 'Sin info'}}
      </template>
    </table-render>
  </div>
</template>

<script>
import { environment } from '@/environments/environment'

export default {
  props: ['dataList', 'user', 'changeSelectedRows'],
  data() {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      URLS: Object.freeze({
        deliveries: 'deliveries',
        returns: 'return-deliveries'
      }),
      selectedRows: []
    }
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    'dataList'() {
      this.setRows()
    },
    selectedRows() {
      this.$emit('changeSelectedRows', this.selectedRows)
    }
  },
  methods: {
    setInitialData() {
      this.setSchema()
      this.setRows()
    },
    setSchema() {
      this.schema = [
        {label: 'Estado', key: 'status.name'},
        {label: 'N° de devolucion', key: 'num_return', useSlot: true},
        {label: 'N° de envío', key: 'num_imported', useSlot: true},
        {label: 'Orden de flete', key: 'tracking_number', useSlot: true},
        {label: 'Cliente', key: 'origin.contact.name'},
        {label: 'Comuna', key: 'origin.address.level1'},
        {label: 'Courier', key: 'carrier.name'},
        {label: 'Fecha creación', key: 'created_at'}
      ]
      if (['marketplace', 'admin'].includes(this.user?.role)) {
        this.schema.unshift({label: 'Empresa', key: 'shipper.name'})
      }
    },
    setRows() {
      this.rows = this.dataList?.data.map(row => {
        return {
          ...row,
          created_at: this.$options.filters.dbDateUTCToGMTlocal(row.created_at)
        }
      })
      this.keyTableRender++
    },
    goTo (id, name) {
      return `${name}/${id}`
    }
  }
}
</script>
