<template>
  <div class="company-dialog">
    <b-modal
      id="ModalPrintPreview" 
      centered
      modal-class="modal-warning dialog-600 custom-dialog"
      hide-footer
      title="Etiquetas"
    >
      <div class="mt-0 text-center">
        <b-tabs content-class="mt-3">
          <b-tab v-for="(group, index) in orderedLabels.labels" :key="index">
            <template #title>
              <div class="text-center">
                <span class="font-weight-bold">{{group.carrier}}</span>
              </div>
            </template>
            <div class="col-md-12">
              <iframe width="100%" height="700" :src="group.url"></iframe>
              <div class="my-1 text-center">
                <span>Si no puedes previsualizar la etiqueta de {{group.carrier}} haz <a :href="group.url" target="_blank">click aquí</a></span>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default { props: ['orderedLabels'] }
</script>
