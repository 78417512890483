<template>
  <div class="">
    <b-card>
      <div v-if="loading.mySession">
        <form-render
          ref="printedForm"
          :key="keyFormRender"
          class="mb-2"
          :form.sync="formFilter"
          :fields="fields"
          @send="filterForm"
          :buttonSend="buttonSend"
          containerButtonsClass="col-sm-12 col-md-4 col-lg-4 container-button mt-2"
        >
          <template #buttons>
            <b-dropdown class="dropdown-buttons-print" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light">
              <template #button-content><feather-icon icon="SettingsIcon" /></template>
              <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
              <b-dropdown-item v-b-modal.ModalReturnsFilter>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
            </b-dropdown>
            <b-button variant="warning" @click="openModal('modalMultiSearch')">Multiselección</b-button>
          </template>
        </form-render>
        <div class="col-md-12">
          <span v-b-tooltip="!selectedRows.length ? $t('msg-alert-disabled-panel') : ''">
            <b-button variant="warning" class="ml-2" @click="openModal('ModalPrintReturn')" :disabled="!selectedRows.length">
              <feather-icon icon="PrinterIcon" />
            </b-button>
            <span class="ml-2"
              ><strong> {{selectedRows.length}} {{ selectedRows.length !== 1 ? 'devoluciones seleccionadas' : 'devolución seleccionada'}} </strong></span
            >
          </span>
        </div>
        <div v-if="loading.first" class="table-render-skeleton mt-1">
          <b-skeleton-table :rows="10" :columns="10" :table-props="{ }" />
        </div>
        <print-return-list v-if="!loading.first" @changeSelectedRows="changeSelectedRows" :dataList="returnDeliveries" :user="mySession" class="mt-1" />
        <pagination v-if="!loading.first" :pagination="pagination" :noDigits="true" />
      </div>
      <div v-if="!loading.mySession">
        <div class="d-flex">
          <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field"/>
          <b-skeleton height="40px" width="20%" class="m-2 spacing-label-field"/>
          <b-skeleton height="40px" width="4%" class="m-2 spacing-label-field"/>
          <b-skeleton height="40px" width="6%" class="m-2 spacing-label-field"/>
        </div>
        <b-skeleton height="40px" width="4%" class="m-2 spacing-label-field"/>
        <div class="table-render-skeleton mt-1">
          <b-skeleton-table :rows="10" :columns="10" :table-props="{ }" />
        </div>
      </div>
    </b-card>
    <modal-returns-filter @filter="filterList" ref="ModalReturnsFilter" :date="formFilter.date_range" :isPrintReturn="true" />
    <modal-print-return :deliveriesIds="selectedRows" @sendLabelResponse="collectLabelsPrintData"></modal-print-return>
    <modal-print-preview :orderedLabels="orderedLabels" ref="ModalPrintPreview"></modal-print-preview>
    <modal-multi-search @filter="fullFilters" ref="modalMultiSearch"></modal-multi-search>
  </div>
</template>

<script>
import * as moment from 'moment'
import ModalReturnsFilter from '../ModalReturnsFilter'
import ModalPrintPreview from './components/ModalPrintPreview.vue'
import ModalPrintReturn from './components/ModalPrintReturn.vue'
import { mapGetters } from 'vuex'
import PrintReturnList from './components/PrintReturnList.vue'
import ModalMultiSearch from '@/views/modules/deliveries/create/generate-labels/ModalMultiSearch.vue'

export default {
  components: {ModalReturnsFilter, ModalPrintPreview, ModalPrintReturn, PrintReturnList, ModalMultiSearch},
  data () {
    return {
      keyFormRender: 0,
      formFilter: {},
      fields: [],
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      loading: {
        first: false,
        mySession: false
      },
      initialDateRange: {
        start: moment().add(-35, 'days').toDate(),
        end: moment().add(7, 'days').toDate()
      },
      pagination: {
        page: 1,
        total: 10,
        limit: 10
      },
      selectedRows: [],
      orderedLabels: [],
      user: null,
      returnDeliveries: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    if (this.mySession?.id) {
      this.loading.mySession = true
      this.setInitialData()
    }
  },
  watch: {
    mySession () {
      if (this.mySession?.id) {
        this.loading.mySession = true
        this.setInitialData()
      }
    },
    'pagination.page' () {
      this.getReturnsDelivery()
    }
  },
  methods: {
    setInitialData () {
      this.formFilter.date_range = this.initialDateRange
      this.user = this.$session.get('cas_user')
      this.fields = [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: 'Fecha de creación', range: true, containerClass: 'col-sm-12 col-md-4 col-lg-4'},
        { fieldType: 'FieldInput', label: this.$t('N. de envío'), name: 'reference', placeholder: this.$t('Buscar envío'), containerClass: 'col-sm-12 col-md-4 col-lg-4' }
      ]
      this.customTableByRole()
      this.filterList(this.formFilter)
    },
    filterForm (form, isExportation) {
      this.filterList({...this.advancedFilters, ...form}, isExportation)
    },
    filterList (data, isExportation = false) {
      this.filters = {}
      this.filters['status_id'] = 5
      this.filters.created_at_from = this.$options.filters.moment(this.formFilter.date_range.start, 'YYYY-MM-DD h:mm:ss')
      this.filters.created_at_to = this.$options.filters.moment(this.formFilter.date_range.end, 'YYYY-MM-DD h:mm:ss')
      this.filters['delivery_type'] = 'return'
      Object.keys(data).map(key => {
        if (data[key] !== null && data[key] !== '') {
          if (key !== 'date_range') {
            if (key === 'shipper') this.filters['company_id'] = data[key].id
            else if (key === 'country') this.filters['country_code'] = data[key].code
            else if (key === 'service') this.filters['carrier_service'] = data[key].code
            else if (key === 'carrier') this.filters['carrier_code'] = data[key].carrier_code
            else if (key === 'place') this.filters['origin_place_platform_id'] = data[key].id
            else if (key === 'flow_action') this.filters['status_ids'] = data[key].map(action => action.id).join('-')
            else if (key === 'reference') this.filters['reference'] = data[key]
            else if (key === 'number') this.filters['references'] = data[key]
            else if (key === 'shipper') {
              this.filters['company_id'] = data[key].id
              this.filters['shipper_id'] = data[key].id
            }
            else this.filters[key] = data[key]

          } else {
            this.filters.date_range = data[key]
          }
        }
      })
      delete this.filters.date_range
      if (!isExportation) {
        this.pagination.page !== 1 ? this.pagination.page = 1 : this.getReturnsDelivery()
      } else {
        //Se llama desde aqui para poder tomar el dato del filtro simple tambien
        this.$refs.ModalReturnsFilter.exportData(this.filters)
      }
    },
    getReturnsDelivery () {
      this.selectedRows = []
      this.loading.first = true
      const queryParams = this.setPayload('filterList')
      this.$store.dispatch('fetchService', { name: 'getDeliveriesReturn', queryParams,
        onSuccess: (resp) => {
          this.returnDeliveries = resp
          this.loading.first = false
        },
        onError: () => this.loading.printed = false})
    },
    setPayload (typePayload) {
      const payload =  {
        ...this.filters
      }
      payload.page = this.pagination.page
      payload.limit = 50
      if (this.user.role !== 'admin') payload.user = {}
      const roles = {
        admin: 1,
        ecommerce: 2,
        pudo: 3,
        marketplace: 4
      }

      if (typePayload === 'export') {
        if (['marketplace'].includes(this.user.role)) {
          payload.user.organization_id = this.user.organization.id
          payload.user.role_id = roles[this.user.role]
        }
        if (['ecommerce'].includes(this.user.role)) {
          payload.user.shipper_id = this.user.shipper.id
          payload.user.role_id = roles[this.user.role]
        }
      } else if (typePayload === 'filterList') {
        if (['marketplace'].includes(this.user.role)) payload.organization_id = this.user.organization.id
        if (['ecommerce'].includes(this.user.role)) payload.shipper_id = this.user.shipper.id
      }
      return payload
    },
    changeSelectedRows (rows) {
      this.selectedRows = rows
    },
    customTableByRole () {
      if (this.user.role === 'ecommerce' && this.schema?.length) {
        this.schema.shift()
      }
    },
    collectLabelsPrintData(data) {
      this.orderedLabels = data
      this.$bvModal.hide('ModalPrintReturn')
      this.openModal('ModalPrintPreview')
      this.loading.first = true
      setTimeout(() => (
        this.filterForm()
      ), 2000)
    },
    openModal (modal) {
      this.$bvModal.show(modal)
    },
    cleanFilter () {
      this.formFilter = {
        date_range: this.initialDateRange
      }
      this.pagination.page = 1
      this.filterList(this.formFilter)
    },
    fullFilters(data) {
      this.filterList(data, false)
      this.$refs.modalMultiSearch.resetForm()
    }
  }
}
</script>
<style lang="scss">
  .dropdown-buttons-print {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
</style>
