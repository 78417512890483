<template>
  <div class="company-dialog">
    <b-modal
      id="ModalPrintReturn"
      centered
      modal-class="modal-warning dialog-600 custom-dialog"
      hide-footer
    >
      <div class="mt-0 text-center">
        <h2 class="mb-2"><strong>Tipo de impresión</strong></h2>
        <div class="message-print p-1 m-2 text-justify"> <strong>{{deliveriesIds.length}} {{ deliveriesIds.length !== 1 ? 'devoluciones seleccionadas' : 'devolución seleccionada'}}</strong> </div> 
        <div class="container my-2">
          <div class="row d-flex justify-content-around">
            <b-button class="col-md-3" variant="warning" :disabled="loading.type_1 || loading.type_2 || loading.type_3" @click="selectPrintType(1)"> <b-spinner v-if="loading.type_1" type="grow"></b-spinner> <span v-else>{{$t('Zebra')}}</span> </b-button>
            <b-button class="col-md-3" variant="warning" :disabled="loading.type_1 || loading.type_2 || loading.type_3" @click="selectPrintType(2)"> <b-spinner v-if="loading.type_2" type="grow"></b-spinner> <span v-else>{{$t('Hoja Carta')}}</span> </b-button>
            <b-button class="col-md-3" variant="warning" :disabled="loading.type_1 || loading.type_2 || loading.type_3" @click="selectPrintType(3)"> <b-spinner v-if="loading.type_3" type="grow"></b-spinner> <span v-else>{{$t('ZPL')}}</span> </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
export default {
  props: {
    deliveriesIds: {required: false, type: Array}
  },
  data() {
    return {
      baseService: new BaseServices(this),
      loading: {
        type_1: false,
        type_2: false,
        type_3: false
      }
    }
  },
  methods: {
    selectPrintType (type) {
      const data = { type }
      data.return_deliveries = this.deliveriesIds
      switch (type) {
      case 1: 
        this.loading.type_1 = true
        break
      case 2: 
        this.loading.type_2 = true
        break
      case 3: 
        this.loading.type_3 = true
        break
      }
      this.printLabels(data)
    },
    printLabels (data) {
      this.baseService.callService('postPrintReturns', data, data)
        .then(resp => {
          this.$emit('sendLabelResponse', resp)
        })
        .catch(error => {
          this.$alert(this.$t('msg-problem-delivery-label', {code: error}))
        })
        .finally(_ => {
          this.loading.type_1 = false
          this.loading.type_2 = false
          this.loading.type_3 = false
        })
    }
  }
}
</script>

<style>
.message-print {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  border: 1px solid #faebcc;
  border-radius: 4px;
}
</style>